import React from "react";
import img from '../../Assets/img.png'
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  TechImg,
  ContactWrapper,
} from "./AboutElements";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <Image
            src={img}
            alt="man-svgrepo"
          />
          <div className="AboutBio">
            <p>
              Hello! My name is <strong>Franco Caspani</strong> ,
              experienced Full Stack Developer with a Specialization in Node.js and AWS, possessing over two years of experience in backend and frontend development. Currently pursuing a Software Engineering degree to further enhance my expertise. Proficient in JavaScript, React, Node.js, Express, and MongoDB. Skilled in API development and optimization, with a particular focus on scalable and secure solutions. Eager to contribute to innovative projects, leveraging my background in agile environments and advanced technology stack.
            </p>
          </div>
          <div className="AboutBio tagline2">
            I have become confident using the following technologies.
          </div>
          <Technologies>
            {stackList.map((stack, index) => (
              <TechImg src={stack.img} alt={stack.name} key={index} />
            ))}
          </Technologies>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
