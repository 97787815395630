import video from '../Assets/mtGif.gif'


export const ProjectList = [
  {
    img: 'https://i.imgur.com/sKJxfdQ.png',
    video: 'https://i.imgur.com/nlMyrXo.gif',
    title: "My Industrial Home",
    description: "React e-commerce website. With user authentication, integration with PayPal, and custom REST API",
    tech_stack: "HTML, CSS, MERN",
    github_url: "https://github.com/francocaspani/my-industrial-home-challangeMindHub",
    demo_url: "https://my-industrial-home-challange-mind-hub.vercel.app/",
  },
  {
    img: 'https://i.imgur.com/2BawY0o.png',
    video: video,
    title: "MyTinerary",
    description:
      "React website & React Native app. With user authentication, and custom REST API",
    tech_stack: "HTML, CSS, MERN",
    github_url: "https://github.com/francocaspani/mytinerary-caspani",
    demo_url: "https://mytinerary-caspani.vercel.app/",
  },
  {
    img: 'https://i.imgur.com/v0kK8l2.png',
    title: "Mindy Pet Shop",
    video: 'https://i.imgur.com/i2rQmXp.gif',
    description: "Pet Shop E-commerce website developed in 48hrs in groups for MindHub",
    tech_stack: "HTML, CSS, JavaScript",
    github_url: "https://github.com/francocaspani/MindyPetshopMindhub",
    demo_url: "https://francocaspani.github.io/MindyPetshopMindhub/",
  },
  {
    img: 'https://i.imgur.com/pCEdAFV.png',
    title: "Amazing Events",
    video: 'https://i.imgur.com/LnJREoy.gif',
    description:
      "Simple events website developed with JavaScript vanilla",
    tech_stack: "HTML, CSS, JavaScript",
    github_url: "https://github.com/francocaspani/amazingEventsMindhub",
    demo_url: "https://amazing-events-mindhub-pearl.vercel.app/",
  },
];

export const ProjectListMobile = [
  {
    img: 'https://i.imgur.com/VplYhPv.png',
    video: 'https://i.imgur.com/lwamwPT.gif',
    title: "My Industrial Home Mobile App",
    description: "React Native e-commerce app. With user authentication and custom REST API",
    tech_stack: "React Native",
    github_url: "https://github.com/francocaspani/my-industrial-home-react-native",
  },
  {
    img: 'https://i.imgur.com/IGlp1gU.png',
    video: 'https://i.imgur.com/Lol46pQ.gif',
    title: "MyTinerary Mobile App",
    description:
      "React Native app. With user authentication, and custom REST API",
    tech_stack: "React Native",
    github_url: "https://github.com/francocaspani/mytinerary-caspani-react-native",
  }
];
// --------------------------------------- Skills

export const stackList = [
  {
    name: "HTML",
    img: "https://img.shields.io/badge/HTML-%23E34F26.svg?style=flat&logo=html5&logoColor=white",
  },
  {
    name: "CSS",
    img: "https://img.shields.io/badge/CSS-%231572B6.svg?style=flat&logo=css3&logoColor=white",
  },
  {
    name: "JavaScript",
    img: "https://img.shields.io/badge/JavaScript-%23F7DF1E.svg?style=flat&logo=javascript&logoColor=black",
  },
  {
    name: "ReactJS",
    img: "https://img.shields.io/badge/React-%2320232a.svg?style=flat&logo=react&logoColor=%2361DAFB",
  },
  {
    name: "Expo",
    img: "https://img.shields.io/badge/Expo-000000?style=flat&logo=expo&logoColor=white",
  },
  {
    name: "ExpressJS",
    img: "https://img.shields.io/badge/Express.js-%23404d59.svg?style=flat&logo=express&logoColor=%2361DAFB",
  },
  {
    name: "JWT",
    img: "https://img.shields.io/badge/JWT-%23000000.svg?style=flat&logo=JSON%20web%20tokens&logoColor=white",
  },
  {
    name: "NodeJS",
    img: "https://img.shields.io/badge/Node.js-%2343853D.svg?style=flat&logo=node.js&logoColor=white",
  },
  {
    name: "React Native",
    img: "https://img.shields.io/badge/React_Native-%23000000.svg?style=flat&logo=react&logoColor=61DAFB",
  },
  {
    name: "ReduxJS",
    img: "https://img.shields.io/badge/Redux-%23593d88.svg?style=flat&logo=redux&logoColor=white",
  },
  {
    name: "Socket.io",
    img: "https://img.shields.io/badge/Socket.io-%23FF9900.svg?style=flat&logo=socket.io&logoColor=white",
  },
  {
    name: "MongoDB",
    img: "https://img.shields.io/badge/MongoDB-%234ea94b.svg?style=flat&logo=mongodb&logoColor=white",
  },
  {
    name: "Postman",
    img: "https://img.shields.io/badge/Postman-%23FF6C37.svg?style=flat&logo=postman&logoColor=white",
  },
  {
    img: "https://img.shields.io/badge/PostgreSQL-%23404d59.svg?style=flat&logo=postgresql&logoColor=%2361DAFB",
    name: "PostgreSQL",
  },
  {
    name: "AWS",
    img: "https://img.shields.io/badge/AWS-%23FF9900.svg?style=flat&logo=amazonwebservices&logoColor=white",
  },
  {
    name: 'NGINX',
    img: 'https://img.shields.io/badge/NGINX-%23009639.svg?style=flat&logo=nginx&logoColor=white'
  },
  {
    name: 'Google Cloud/API',
    img: 'https://img.shields.io/badge/Google_Cloud-%234285F4.svg?style=flat&logo=google-cloud&logoColor=white'
  },
  {
    name: 'TypeScript',
    img: 'https://img.shields.io/badge/TypeScript-%23007ACC.svg?style=flat&logo=typescript&logoColor=white'
  },
  {
    name: 'Docker',
    img: 'https://img.shields.io/badge/Docker-%230db7ed.svg?style=flat&logo=docker&logoColor=white'
  },
  {
    name: 'Git',
    img: 'https://img.shields.io/badge/Git-%23F05032.svg?style=flat&logo=git&logoColor=white'
  },
  {
    name: 'GitHub',
    img: 'https://img.shields.io/badge/GitHub-%23121011.svg?style=flat&logo=github&logoColor=white'
  },
  {
    name: 'Trello',
    img: 'https://img.shields.io/badge/Trello-%23026AA7.svg?style=flat&logo=trello&logoColor=white'
  },
  {
    name: 'Slack',
    img: 'https://img.shields.io/badge/Slack-%234A154B.svg?style=flat&logo=slack&logoColor=white'
  },
  {
    name: 'Agile',
    img: 'https://img.shields.io/badge/Agile-%230175C2.svg?style=flat&logo=agile&logoColor=white'
  },
  {
    name: 'Scrum',
    img: 'https://img.shields.io/badge/Scrum-%230175C2.svg?style=flat&logo=scrum&logoColor=white'
  },
];
